<template>
  <div>
    <v-tabs
      v-model="tab"
      show-arrows
      class="mb-4"
    >
      <v-tab
        key="profile"
      >
        Profile
      </v-tab>
      <v-tab
        key="security"
      >
        security
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item
        key="profile"
      >
        <profile></profile>
      </v-tab-item>
      <v-tab-item
        key="security"
      >
        <security></security>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'
import Profile from './view-tabs/Profile.vue'
import Security from './view-tabs/Security.vue'

export default {
	components: { Profile, Security },
	data() {
		return {
			tab: null,
			icons: { mdiArrowLeft },
		}
	},
}
</script>
<style>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical),
.theme--light.v-tabs > .v-tabs-bar {
	box-shadow: none !important;
	background-color: transparent !important;
	border-color: transparent !important;
}
</style>
