<template>
  <v-card
    flat
  >
    <div
      class="mt-5"
    >
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="changePassword"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.new_password"
                outlined
                :type="isNewPasswordVisible ? 'text' : 'password'"
                label="New Password"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                hide-details="auto"
                class="mb-2"
                dense
                :rules="[validators.required, validators.passwordValidator]"
                :error-messages="errors.new_password"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                @change="errors.new_password = []"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.confirm_password"
                outlined
                :type="isConfirmPasswordVisible ? 'text' : 'password'"
                label="Confirm Password"
                :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                hide-details="auto"
                class="mb-2"
                dense
                :rules="[validators.required, validators.confirmedValidator(formData.confirm_password, formData.new_password)]"
                :error-messages="errors.confirm_password"
                @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                @change="errors.confirm_password = []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-alert
                color="warning"
                text
              >
                <p class="font-weight-semibold mb-1">
                  Ensure that these requirements are met
                </p>
                <p class="text-sm mb-0">
                  Minimum 8 characters long, uppercase &amp; symbol
                </p>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
              >
                Change password
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click.prevent="resetForm"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { required, passwordValidator, confirmedValidator } from '@core/utils/validation'

export default {
	data() {
		return {
			icons: {
				mdiEyeOutline,
				mdiEyeOffOutline,
			},
			formData: {
				new_password: '',
				confirm_password: '',
			},
			form: null,
			valid: true,
			errors: {},
			validators: { required, passwordValidator, confirmedValidator },
			isConfirmPasswordVisible: false,
			isNewPasswordVisible: false,
		}
	},
	mounted() {
		this.resetForm()
	},
	methods: {
		resetForm() {
			this.$refs.form.resetValidation()
			this.$refs.form.reset()
			this.errors = []
		},
		changePassword() {
			this.$store
				.dispatch('auth/changePassword', this.formData)
				.then(response => {
					this.isVisible = false
					this.$emit('onComplete')
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
				})
				.catch(error => {
					const { errors } = error.response.data
					if (errors) {
						this.$set(this, 'errors', errors)
						this.$set(this, 'valid', false)
					}
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
	},
}
</script>
