<template>
  <v-card
    flat
  >
    <div
      class="mt-5"
    >
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="updateProfile"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.name"
                outlined
                dense
                label="Name"
                hide-details="auto"
                class="mb-6"
                :rules="[validators.required]"
                :error-messages="errors.name"
                @change="errors.name = []"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :value="formData.email"
                outlined
                dense
                label="Email"
                hide-details="auto"
                class="mb-6"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
              >
                Save
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click.prevent="loadFormData"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mdiPencilOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import { required } from '@core/utils/validation'

export default {
	data() {
		return {
			icons: { mdiPencilOutline },
			formData: {},
			form: null,
			valid: true,
			errors: {},
			validators: { required },
		}
	},
	computed: { ...mapGetters('auth', ['user']) },
	watch: {
		user() {
			this.loadFormData()
		},
	},
	mounted() {
		this.loadFormData()
	},
	methods: {
		loadFormData() {
			this.formData = JSON.parse(JSON.stringify(this.user))
		},
		updateProfile() {
			this.$store
				.dispatch('auth/updateProfile', this.formData)
				.then(response => {
					this.isVisible = false
					this.$store.dispatch('auth/getProfile')
					this.$store.commit('app/SET_SNACKBAR_SUCCESS', response.data?.message)
				})
				.catch(error => {
					const { errors } = error.response.data
					if (errors) {
						this.$set(this, 'errors', errors)
						this.$set(this, 'valid', false)
					}
					this.$store.commit('app/SET_SNACKBAR_ERROR', error.response.data?.message)
				})
		},
	},
}
</script>
